import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import * as api from '@fwk-client/utils/api';
import * as socket from '@fwk-client/utils/socket';
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { types as applicationTypes } from '@root/src/client/store/application';
import { authenticationTypes } from '@fwk-client/store/types';

import { roles as apiRoles } from '@igotweb/core-api/src/roles';

export interface GenericStoreModuleTypes {
  getters : {
    GET_USER_MODULES : string
    GET_USER_SELECTED_MODULE : string
  },
  mutations : {
    SET_USER_MODULES : string
    SET_USER_SELECTED_MODULE : string
  },
}

export interface GenericModuleAdminInput {
  apiModulePath:string
  storeModule:string
  storeModuleTypes:GenericStoreModuleTypes
}

export function useGenericModuleAdmin(props:GenericModuleAdminInput, context:any) { 
  const app = getApp();
  const store = useStore();

  const hasUserRole = store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE];

  var isListLoading:Ref<boolean> = ref(false);
  var list:Ref<any[]> = ref([]);

  const updateInputWithTargetDB = (input:any) => {
    // We check if we have a target DB for the content
    var targetDB = store.getters['application/'+applicationTypes.getters.GET_ADMIN_TARGET_DB];
    if(targetDB) {
      input.targetDB = targetDB;
    }
  }

  const callModuleAdmin = async (path:string, input?:any, formData?:FormData, apiOptions?:api.ApiOptions) => {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      ...apiOptions,
      app: app
    }
    if(!input) { input = {}; }
    updateInputWithTargetDB(input);
    if(formData) {
      return api.postAPIFormData(props.apiModulePath + path, formData, options, input)
    }
    return api.postAPI(props.apiModulePath + path, input, options);
  }

  const emitModuleAdmin = async (path:string, apiOptions?:{completedHandlers?:Function[], successesHandler?:Function, errorsHandler?:Function, updatesHandler?:Function}, input?:any, file?:File) => {
    // We need to get the list of available companies for the current logged in user
    var options:socket.SocketVueOptions =  {
      ...apiOptions,
      app: app
    }
    if(!input) { input = {}; }
    updateInputWithTargetDB(input);
    return socket.request(props.apiModulePath + path, input, options, file);
  }

  const updateListModules = async () => {

    isListLoading.value = true;
    try {
      var response = await callModuleAdmin('/modules/list');
      store.commit(props.storeModule+'/'+props.storeModuleTypes.mutations.SET_USER_MODULES, response.modules ? response.modules : []);
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }

  const getModuleFromID = (moduleID:string) => {
    var module = modules.value.filter((module:any) => {
      return module.module._id == moduleID
    })
    if(module.length == 1) { return module[0].module; }
    return null;
  }

  /**
   * selectModule
   * This method update the selected module by loading all associated details
   * @param moduleID 
   */
  const selectModule = async (moduleID:string) => {

    // We need to load the site and update the store
    try {
      var response = await callModuleAdmin('/'+moduleID+'/module');
      store.commit(props.storeModule+'/'+props.storeModuleTypes.mutations.SET_USER_SELECTED_MODULE, response.module ? response.module : undefined);
    }
    catch(error:any) {
      console.log(error);
    }
  }

  const modules = computed(() => {
    return store.getters[props.storeModule+'/'+props.storeModuleTypes.getters.GET_USER_MODULES];
  })

  const selectedModule = computed(() => {
    return store.getters[props.storeModule+'/'+props.storeModuleTypes.getters.GET_USER_SELECTED_MODULE];
  })

  const isModuleUpdateAllowed = () => {
    return hasUserRole(apiRoles.superadmin);
  }

  const isModuleCreateAllowed = () => {
    return hasUserRole(apiRoles.superadmin);
  }

  const isModuleRemoveAllowed = () => {
    return hasUserRole(apiRoles.superadmin);
  }

  const moduleForm:any = reactive({
    code: '',
    name: '',
    isActivated: true
  });

  const updateModuleFormForUpdate = (module:any) => {
    moduleForm.moduleID = (module && module._id) ? module._id : undefined,
    moduleForm.name =  module.name,
    moduleForm.isActivated = module.isActivated,
    moduleForm.code = module.code
  }

  const createModule = async () => {

    var result:any = {
      created: false
    }

    var input = {
      ...moduleForm
    }

    try {
      var response = await callModuleAdmin('/modules/create', input);
      if(response.created) {  
        // We update the fields with resulting agency
        updateModuleFormForUpdate(response.module)

        // We update the list of modules
        updateListModules();

        result.created = true;
        result.module = response.module;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const updateModule = async () => {

    var result:any = {
      updated: false
    }

    var input:any = {
      ...moduleForm
    }

    try {
      var response = await callModuleAdmin('/'+moduleForm.moduleID+'/module/update', input);
      if(response.updated) {  
        // We update the fields with resulting module
        updateModuleFormForUpdate(response.module)

        // We update the list of modules
        updateListModules();

        result.updated = true;
        result.module = response.module;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const removeModule = async (moduleID:string) => {

    isListLoading.value = true;

    try {
      var response = await callModuleAdmin('/'+moduleID+'/module/remove');
      if(response.removed) {
        updateListModules();
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return true;
  }

  const getAdminSettings = async (module:any) => {

    var result:any = {
      adminSettings: undefined
    }

    try {
      var response = await callModuleAdmin('/'+module._id+'/module/settings/admin');
      if(response.adminSettings) {  
        result.adminSettings = response.adminSettings;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const updateAdminSettings = async (module:any, adminSettings:any) => {

    var result:any = {
      updated: false
    }

    var input:any = {
      adminSettings: adminSettings
    }

    try {
      var response = await callModuleAdmin('/'+module._id+'/module/settings/admin/update', input);
      if(response.updated) {  
        result.updated = true;
        result.module = response.module;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const getClientSettings = async (module:any) => {

    var result:any = {
      clientSettings: undefined
    }

    try {
      var response = await callModuleAdmin('/'+module._id+'/module/settings/client');
      if(response.clientSettings) {  
        result.clientSettings = response.clientSettings;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const updateClientSettings = async (module:any, clientSettings:any) => {

    var result:any = {
      updated: false
    }

    var input:any = {
      clientSettings: clientSettings
    }

    try {
      var response = await callModuleAdmin('/'+module._id+'/module/settings/client/update', input);
      if(response.updated) {  
        result.updated = true;
        result.module = response.module;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  return {
    callModuleAdmin,
    emitModuleAdmin,
    isListLoading,
    modules,
    getModuleFromID,
    selectedModule,
    selectModule,
    moduleForm,
    createModule,
    updateModule,
    removeModule,
    isModuleUpdateAllowed,
    isModuleCreateAllowed,
    isModuleRemoveAllowed,
    updateModuleFormForUpdate,
    getAdminSettings,
    updateAdminSettings,
    getClientSettings,
    updateClientSettings,
    updateListModules
  }
  
}