import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { useModuleAdmin } from "./useModuleAdmin";

interface ReportAdminInput {
}

export function useReportAdmin(props:ReportAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();
  
  const { selectedModule, callModuleAdmin } = useModuleAdmin(props, {emit})

  /*

  const filters:any = reactive({
    companyName: undefined,      
    periods: [],
  })

  const hasFilters = computed(() => {
    return (filters.companyName != undefined && filters.companyName != "") || (filters.periods != undefined && filters.periods.length > 0);
  })

  */

  var isListCheckedFilesLoading:Ref<boolean> = ref(false);
  var isListDeclarationsLoading:Ref<boolean> = ref(false);

  const checkedFiles:Ref<[]> = ref([]);
  const declarations:Ref<[]> = ref([]);
  // const companies:Ref<string[]> = ref([]);

  const updateListCheckedFiles = async () => {

    var input:any = {
      // companyName:filters.companyName ? filters.companyName : undefined,
      // periods:(filters.periods && filters.periods.length > 0) ? filters.periods : undefined
    }

    try {
      isListCheckedFilesLoading.value = true;
      var response = await callModuleAdmin('/'+selectedModule.value.module._id+'/list-checked-files', input);
      isListCheckedFilesLoading.value = false;
      checkedFiles.value = response.checkedFiles ? response.checkedFiles : [];
      /*
      // We update the list of companies only when request is done without filter.
      if(!hasFilters.value) {
        companies.value = [ ... new Set(reports.value.map((report:any) => report.context.companyName)) ].sort();
      }
      */
    }
    catch(error:any) {
      isListCheckedFilesLoading.value = false;
      console.log(error);
    }

  }

  const updateListDeclarations = async () => {

    var input:any = {
      // companyName:filters.companyName ? filters.companyName : undefined,
      // periods:(filters.periods && filters.periods.length > 0) ? filters.periods : undefined
    }

    try {
      isListDeclarationsLoading.value = true;
      var response = await callModuleAdmin('/'+selectedModule.value.module._id+'/list-generated-files', input);
      isListDeclarationsLoading.value = false;
      declarations.value = response.declarations ? response.declarations : [];
      /*
      // We update the list of companies only when request is done without filter.
      if(!hasFilters.value) {
        companies.value = [ ... new Set(reports.value.map((report:any) => report.context.companyName)) ].sort();
      }
      */
    }
    catch(error:any) {
      isListDeclarationsLoading.value = false;
      console.log(error);
    }

  }

  const removeCheckedFile = async (checkedFileID:string) => {

    var result:any = {
      removed:false
    }

    var input = {
      "checkedFileID" : checkedFileID
    }

    try {
      isListCheckedFilesLoading.value = true;
      var response = await callModuleAdmin('/'+selectedModule.value.module._id+'/checked-file/remove', input);
      isListCheckedFilesLoading.value = false;
      if(response.removed) {
        result.removed = true;
      }
      return result;
    }
    catch(error:any) {
      isListCheckedFilesLoading.value = false;
      console.log(error);
    }
    return result;

  }

  const removeDeclaration = async (declarationID:string) => {

    var result:any = {
      removed:false
    }

    var input = {
      "declarationID" : declarationID
    }

    try {
      isListDeclarationsLoading.value = true;
      var response = await callModuleAdmin('/'+selectedModule.value.module._id+'/declaration/remove', input);
      isListDeclarationsLoading.value = false;
      if(response.removed) {
        result.removed = true;
      }
      return result;
    }
    catch(error:any) {
      isListDeclarationsLoading.value = false;
      console.log(error);
    }
    return result;

  }

  const getFileURL = (fileId:any) => {
    var url = '/api/admin/intrastats/'+selectedModule.value.module._id+'/file/'+fileId+'/download';
    return url;
  }

  watch(
    () => selectedModule.value.module._id,
    (val:any, oldVal:any) => {
      // We update the reports
      updateListCheckedFiles();
      updateListDeclarations();
    },
    { deep: false }
  )
    

  return {
    isListCheckedFilesLoading,
    isListDeclarationsLoading,
    updateListCheckedFiles,
    updateListDeclarations,
    checkedFiles,
    declarations,
    getFileURL,
    removeCheckedFile,
    removeDeclaration
  }
  
}