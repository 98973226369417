<template>
  <div class="ibox">
    <div class="ibox-title">
      <h2>{{ $t('intrastats.files.generated-files.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (isListDeclarationsLoading ? ' sk-loading' : '')">

      <div v-if="isListDeclarationsLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <div class="row">

        <b-pagination
            v-model="currentPage"
            :total-rows="declarations.length"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>

      </div>

      <div class="row">

        <b-table 
            outlined striped
            :items="declarations"
            :fields="declarationsFields"
            :per-page="perPage"
            :current-page="currentPage"
            ref="declarationsTable">
          <template v-slot:cell(options)="row">
            <a :href="getFileURL(row.item.XLSFile._id)"><i class="fa fa-file-excel-o"></i></a>
            <a :href="getFileURL(row.item.XMLFile._id)"><i class="fa fa-file-code-o"></i></a>
            <a href="javascript:void(0)" @click="confirmRemoveFile(row.item)"><i class="fa fa-trash-o"></i></a>
          </template>
        </b-table>

      </div>

      <b-modal ref="removeDeclarationModal" 
          hide-header
          @ok="removeFile">
        {{ 
          $t('intrastats.files.confirm-remove-declaration',{
            psiId: declarationToRemove.psiId,
            referencePeriod: declarationToRemove.formattedReferencePeriod
          })
        }}
      </b-modal>

    </div>
  </div>
</template>

<style scoped>
  .b-table .options a:has(i) {
    font-size: 1.3em;
    margin-right: 10px;
  }
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, nextTick, ComputedRef} from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { languagesTypes } from '@fwk-client/store/types';

import { useModuleAdmin } from '../../composables/useModuleAdmin';
import { useReportAdmin } from '../../composables/useReportAdmin';

export default defineComponent({
  props: {
      
  },
  components: {
    
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { selectedModule, modules } = useModuleAdmin(props, context);
    const { declarations, updateListDeclarations, removeDeclaration:removeDeclarationAdmin, getFileURL, isListDeclarationsLoading } = useReportAdmin(props, context);
    updateListDeclarations();
     
    const currentLanguageCode:ComputedRef<string> = computed(() => {
      return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
    })

    const perPage:number = 10;
    const currentPage:Ref<number> = ref(1);

    const removeDeclarationModal:Ref<HTMLElement|null> = ref(null);
    const declarationsTable:Ref<HTMLElement|null> = ref(null);

    const declarationsFields = [
      {
        key: "name",
        label: ""
      },
      {
        key: "formattedCreationDateTime",
        label: ""
      },
      {
        key: "partyName",
        label: ""
      },
      {
        key: "psiId",
        label: ""
      },
      {
        key: "formattedReferencePeriod",
        label: ""
      },
      {
        key: "nbLines",
        label: ""
      },
      {
        key: "options",
        label: "",
        class: "options"
      }
    ];

    const updateTableLabels = () => {
      declarationsFields[0].label = app.$t('intrastats.files.generated-files.headers.name') as string;
      declarationsFields[1].label = app.$t('intrastats.files.generated-files.headers.creation-date-time') as string;
      declarationsFields[2].label = app.$t('intrastats.files.generated-files.headers.party-name') as string;
      declarationsFields[3].label = app.$t('intrastats.files.generated-files.headers.psi-id') as string;
      declarationsFields[4].label = app.$t('intrastats.files.generated-files.headers.reference-period') as string;
      declarationsFields[5].label = app.$t('intrastats.files.generated-files.headers.nb-lines') as string;
      declarationsFields[6].label = app.$t('intrastats.files.generated-files.headers.options') as string;
    }

    /*
    * Component lifecycle
    */
    onMounted(() => {
      updateTableLabels();
    })

    watch(
      currentLanguageCode,
      (val:any, oldVal:any) => {
        updateTableLabels();
      },
      { deep: false }
    )
    
    const emptyDeclaration = {};
    const declarationToRemove:Ref<any> = ref({...emptyDeclaration});

    const onFileRemoved = () => {
      updateListDeclarations();
    }

    const confirmRemoveFile = (checkedFile:any) => {
      declarationToRemove.value = checkedFile;
      // @ts-ignore
      removeDeclarationModal.value.show()
    }

    const removeFile = () => {

      removeDeclarationAdmin(declarationToRemove.value._id).then((result:any) => {
        if(result.removed) {
          // We update the list of files
          onFileRemoved();
        }
        // We reset the report to remove
        declarationToRemove.value = {...emptyDeclaration};

      });
    }

    return {
      declarationsFields,
      declarations,
      isListDeclarationsLoading,
      declarationToRemove,
      confirmRemoveFile,
      removeFile,
      getFileURL,
      declarationsTable,
      removeDeclarationModal,
      perPage,
      currentPage
    }
  }
});
</script>