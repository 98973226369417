<template>

  <div class="ibox">
    <div class="ibox-title">
      <h2>{{ $t('intrastats.files.checked-files.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (isListCheckedFilesLoading ? ' sk-loading' : '')">

      <div v-if="isListCheckedFilesLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <div class="row">

        <b-pagination
            v-model="currentPage"
            :total-rows="checkedFiles.length"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>

      </div>

      <div class="row">

        <b-table 
            outlined striped
            :items="checkedFiles"
            :fields="checkedFilesFields"
            :per-page="perPage"
            :current-page="currentPage"
            ref="checkedFilesTable">
          <template v-slot:cell(options)="row">
            <a :href="getFileURL(row.item.fileId)"><i class="fa fa-cloud-download"></i></a>
            <a href="javascript:void(0)" @click="confirmRemoveFile(row.item)"><i class="fa fa-trash-o"></i></a>
          </template>
        </b-table>

      </div>

      <b-modal ref="removeCheckedFileModal" 
          hide-header
          @ok="removeFile">
        {{ 
          $t('intrastats.files.confirm-remove-file',{
            company: checkedFileToRemove.companyName,
            creationDateTime: checkedFileToRemove.formattedCreationDateTime
          })
        }}
      </b-modal>

    </div>
  </div>


</template>

<style scoped>
  .b-table .options a:has(i) {
    font-size: 1.3em;
    margin-right: 10px;
  }
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, nextTick, ComputedRef} from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { languagesTypes } from '@fwk-client/store/types';

import { useModuleAdmin } from '../../composables/useModuleAdmin';
import { useReportAdmin } from '../../composables/useReportAdmin';

export default defineComponent({
  props: {
      
  },
  components: {
    
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { selectedModule, modules } = useModuleAdmin(props, context);
    const { checkedFiles, updateListCheckedFiles, removeCheckedFile:removeCheckedFileAdmin, getFileURL, isListCheckedFilesLoading } = useReportAdmin(props, context);
    updateListCheckedFiles();
     
    const currentLanguageCode:ComputedRef<string> = computed(() => {
      return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
    })

    const perPage:number = 10;
    const currentPage:Ref<number> = ref(1);

    const removeCheckedFileModal:Ref<HTMLElement|null> = ref(null);
    const checkedFilesTable:Ref<HTMLElement|null> = ref(null);

    const checkedFilesFields = [
      {
        key: "companyName",
        label: ""
      },
      {
        key: "formattedCreationDateTime",
        label: ""
      },
      {
        key: "options",
        label: "",
        class: "options"
      }
    ];

    const updateTableLabels = () => {
      checkedFilesFields[0].label = app.$t('intrastats.files.checked-files.headers.company') as string;
      checkedFilesFields[1].label = app.$t('intrastats.files.checked-files.headers.creation-date-time') as string;
      checkedFilesFields[2].label = app.$t('intrastats.files.checked-files.headers.options') as string;
    }

    /*
    * Component lifecycle
    */
    onMounted(() => {
      updateTableLabels();
    })

    watch(
      currentLanguageCode,
      (val:any, oldVal:any) => {
        updateTableLabels();
      },
      { deep: false }
    )
    
    const emptyCheckedFile = {};
    const checkedFileToRemove:Ref<any> = ref({...emptyCheckedFile});

    const onFileRemoved = () => {
      updateListCheckedFiles();
    }

    const confirmRemoveFile = (checkedFile:any) => {
      checkedFileToRemove.value = checkedFile;
      // @ts-ignore
      removeCheckedFileModal.value.show()
    }

    const removeFile = () => {

      removeCheckedFileAdmin(checkedFileToRemove.value._id).then((result:any) => {
        if(result.removed) {
          // We update the list of files
          onFileRemoved();
        }
        // We reset the report to remove
        checkedFileToRemove.value = {...emptyCheckedFile};

      });
    }

    return {
      checkedFilesFields,
      checkedFiles,
      isListCheckedFilesLoading,
      checkedFileToRemove,
      confirmRemoveFile,
      removeFile,
      getFileURL,
      removeCheckedFileModal,
      checkedFilesTable,
      perPage,
      currentPage
    }
  }
});
</script>